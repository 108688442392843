/* eslint sort-keys: ["error", "asc"] */
/**
 * @file Routes to various pages in the application are defined here rather than being
 * hard-coded into various files.
 */
const routes = {
  api: {
    appeals: "/appeals",
    applicationImports: "/application-imports",
    applications: "/applications",
    benefitYears: "/benefit-years",
    changeRequest: "/change-request",
    claims: "/claims",
    employers: "/employers",
    holidays: "/holidays",
    intermittentLeave: "/report-intermittent-leave-episode",
    leaveAdmins: "/leave-admins",
    oAuthStartFlow: "/oauth/start-flow",
    oAuthToken: "/oauth/token",
    payments: "/payments",
    roles: "/roles",
    users: "/users",
    waitingPeriods: "/waiting-periods",
  },
  applications: {
    address: "/applications/address",
    appeals: {
      haveDocuments: "/applications/appeals/have-documents",
      interpreter: "/applications/appeals/interpreter",
      noticeCircumstances: "/applications/appeals/notice-circumstances",
      noticeDate: "/applications/appeals/notice-date",
      noticeRead: "/applications/appeals/notice-read",
      phone: "/applications/appeals/phone",
      reason: "/applications/appeals/reason",
      representative: "/applications/appeals/representative",
      review: "/applications/appeals/review",
      start: "/applications/appeals/start",
      upload: "/applications/appeals/upload",
    },
    beginAdditionalUserNotFoundInfo:
      "/applications/begin-additional-user-not-found-info",
    bondingLeaveAttestation: "/applications/bonding-leave-attestation",
    caringLeaveAttestation: "/applications/caring-leave-attestation",
    checklist: "/applications/checklist",
    concurrentEmployment: "/applications/concurrent-employment",
    dateOfBirth: "/applications/date-of-birth",
    dateOfChild: "/applications/date-of-child",
    dateOfHire: "/applications/date-of-hire",
    dateOfSeparation: "/applications/date-of-separation",
    department: "/applications/department",
    employerBenefits: "/applications/employer-benefits",
    employerBenefitsIntro: "/applications/employer-benefits-intro",
    employerName: "/applications/employer-name",
    employmentStatus: "/applications/employment-status",
    ethnicity: "/applications/ethnicity",
    familyMemberDateOfBirth: "/applications/family-member-date-of-birth",
    familyMemberName: "/applications/family-member-name",
    familyMemberRelationship: "/applications/family-member-relationship",
    gender: "/applications/gender",
    getReady: "/applications/get-ready",
    importClaim: "/applications/import-claim",
    index: "/applications",
    intermittentFrequency: "/applications/intermittent-frequency",
    language: "/applications/language",
    leavePeriodContinuous: "/applications/leave-period-continuous",
    leavePeriodIntermittent: "/applications/leave-period-intermittent",
    leavePeriodReducedSchedule: "/applications/leave-period-reduced-schedule",
    leaveReason: "/applications/leave-reason",
    leaveSpansBenefitYearsContinuous:
      "/applications/leave-spans-benefit-years/continuous",
    leaveSpansBenefitYearsIntermittent:
      "/applications/leave-spans-benefit-years/intermittent",
    leaveSpansBenefitYearsReduced:
      "/applications/leave-spans-benefit-years/reduced",
    modify: {
      benefitYearInfoReview: "/applications/modify/benefit-year-info-review",
      deleteChangeRequest: "/applications/modify/delete-change-request",
      index: "/applications/modify",
      medToBonding: {
        bondingLeaveDates:
          "/applications/modify/med-to-bonding/bonding-leave-dates",
        dateOfBirth: "/applications/modify/med-to-bonding/date-of-birth",
        index: "/applications/modify/med-to-bonding",
        proofOfBirth: "/applications/modify/med-to-bonding/proof-of-birth",
        redirectToExtension:
          "/applications/modify/med-to-bonding/redirect-to-extension",
        success: "/applications/modify/med-to-bonding/success",
      },
      review: "/applications/modify/review",
      success: "/applications/modify/success",
      type: "/applications/modify/type",
      withdrawLeave: "/applications/modify/withdraw-leave",
    },
    name: "/applications/name",
    noMatchFound: "/applications/no-match-found",
    notifiedEmployer: "/applications/notified-employer",
    otherIncomes: "/applications/other-incomes",
    otherIncomesDetails: "/applications/other-incomes-details",
    paidTimeOffExplainer: "/applications/paid-time-off-explainer",
    paymentMethod: "/applications/payment-method",
    phoneNumber: "/applications/phone-number",
    previousLeaves: "/applications/previous-leaves",
    previousLeavesIntro: "/applications/previous-leaves-intro",
    race: "/applications/race",
    recentlyAcquiredOrMerged: "/applications/recently-acquired-or-merged",
    reducedLeaveSchedule: "/applications/reduced-leave-schedule",
    review: "/applications/review",
    saveProfile: "/applications/save-profile",
    scheduleFixed: "/applications/schedule-fixed",
    scheduleRotating: "/applications/schedule-rotating",
    scheduleRotatingNumberWeeks: "/applications/schedule-rotating-number-weeks",
    scheduleVariable: "/applications/schedule-variable",
    ssn: "/applications/ssn",
    start: "/applications/start",
    stateId: "/applications/state-id",
    status: {
      claim: "/applications/status",
      paymentDetail: "/applications/status/payments/payment-details",
      payments: "/applications/status/payments",
      reportIntermittentLeave: "/applications/status/report-leave",
    },
    // Note that this route is used by the back-end when generating notification emails for
    // the claimant (see notifications.py). Changes here will need to be coordinated with the back-end.
    success: "/applications/success",
    taxWithholding: "/applications/tax-withholding",
    // For routes that don't have a page to point to yet, we can route them
    // to a placeholder page. This allows us to search our code for routes.applications.todo,
    // which is less confusing than seeing routes.applications.checklist
    todo: "/applications/checklist",
    upload: {
      bondingProofOfBirth: "/applications/upload/proof-of-birth",
      bondingProofOfPlacement: "/applications/upload/proof-of-placement",
      caringCertification:
        "/applications/upload/family-member-medical-certification",
      certificationMethod: "/applications/upload/certification-method",
      index: "/applications/upload",
      medicalCertification: "/applications/upload/medical-certification",
      militaryCaregiver: "/applications/upload/military-caregiver",
      militaryCaregiverProof: "/applications/upload/military-caregiver-proof",
      militaryExigency: "/applications/upload/military-exigency",
      militaryExigencyProof: "/applications/upload/military-exigency-proof",
      otherId: "/applications/upload/other-id",
      pregnancyCertification:
        "/applications/upload/pregnancy-medical-certification",
      stateId: "/applications/upload/state-id",
    },
    uploadCertification: "/applications/upload-certification",
    uploadCertificationMethod: "/applications/upload-certification-method",
    uploadCertificationNotAccepted:
      "/applications/upload-certification-not-accepted",
    uploadCertificationType: "/applications/upload-certification-type",
    uploadDocsOptions: "/applications/upload-docs-options",
    uploadId: "/applications/upload-id",
    useProfile: "/applications/use-profile",
    workPatternType: "/applications/work-pattern-type",
  },
  auth: {
    chooseAccountType: "/choose-account-type",
    login: "/login",
    oAuthError: "/oauth-error",
    oAuthLaunch: "/oauth-launch",
    oAuthNotice: "/oauth-notice",
    oAuthReturn: "/oauth-return",
    oAuthStart: "/oauth-start",
  },
  employers: {
    addLA: "/employers/organizations/add-leave-administrator",
    addOrganization: "/employers/organizations/add-organization",
    applications: "/employers/applications",
    cannotVerify: "/employers/organizations/cannot-verify",
    confirmation: "/employers/applications/confirmation",
    dashboard: "/employers/dashboard",
    newApplication: "/employers/applications/new-application",
    orgDetails: "/employers/organizations/details",
    organizations: "/employers/organizations",
    removeLA: "/employers/organizations/remove-leave-administrator",
    review: "/employers/applications/review",
    status: "/employers/applications/status",
    success: "/employers/applications/success",
    verificationSuccess: "/employers/organizations/success",
    verifyContributions: "/employers/organizations/verify-contributions",
    verifyMTC: "/employers/organizations/verify-mtc",
    welcome: "/employers/welcome",
  },
  experian: {
    addressFormat: "/address/format",
    addressSearch: "/address/search",
  },
  external: {
    forms: {
      activeDutyCaringForm:
        "https://www.dol.gov/sites/dolgov/files/WHD/legacy/files/WH-385.pdf",
      activeDutyExigencyForm:
        "https://www.dol.gov/sites/dolgov/files/WHD/legacy/files/WH-384.pdf",
      veteranFMLACaringForm:
        "https://www.dol.gov/sites/dolgov/files/WHD/legacy/files/wh-385-V.pdf",
    },
    inLocoParentis:
      "https://www.dol.gov/agencies/whd/fact-sheets/28C-fmla-eldercare",
    maLegislature: {
      section4:
        "https://malegislature.gov/Laws/GeneralLaws/PartI/TitleXXII/Chapter175M/Section4",
    },
    massNavigator:
      "https://www.mass.gov/forms/mass-navigator-paid-family-and-medical-leave",
    massTaxConnect: "https://mtc.dor.state.ma.us/mtc/_/",
    massgov: {
      addLeaveAdministrators:
        "https://www.mass.gov/info-details/using-the-leave-administrator-dashboard-for-paid-family-and-medical-leave-pfml-applications#add-or-remove-leave-administrators-from-your-organization(s)-",
      appealPfmlDecision:
        "https://www.mass.gov/how-to/appealing-a-paid-family-or-medical-leave-decision",
      appealTimeline:
        "https://www.mass.gov/info-details/paid-family-and-medical-leave-pfml-appeals-timeline",
      applyingSelfOrUnemployed:
        "https://www.mass.gov/how-to/how-to-apply-for-paid-family-or-medical-leave-if-you-are-self-employed-or-unemployed",
      applyingToMilitaryLeave:
        "https://www.mass.gov/how-to/how-to-apply-for-military-related-paid-family-leave",
      approvalTimeline: "https://mass.gov/pfml/application-timeline",
      benefitYearEligibility:
        "https://www.mass.gov/info-details/paid-family-and-medical-leave-pfml-overview-and-benefits#:~:text=Most%20Massachusetts%20employees%20are%20eligible,Family%20and%20Medical%20Leave%20benefits.",
      benefitYearImportance:
        "https://www.mass.gov/info-details/how-pfml-weekly-benefit-amounts-are-calculated-andor-changed#benefit-year-:~:text=Why%20is%20the%20benefit%20year%20important%3F",
      benefitYearLeaveSpansTwo:
        "https://www.mass.gov/info-details/how-pfml-weekly-benefit-amounts-are-calculated-andor-changed#benefit-year-:~:text=What%20happens%20if%20my%20application%20spans%20two%20benefit%20years%3F",
      benefitYearWhatIs:
        "https://www.mass.gov/info-details/how-pfml-weekly-benefit-amounts-are-calculated-andor-changed#:~:text=What%20is%20a%20benefit%20year%3F",
      benefitsCalculator: "https://mass.gov/pfml/benefits-calculator",
      benefitsGuide_aboutBondingLeave:
        "https://www.mass.gov/family-leave-bonding",
      benefitsGuide_benefitsAmountDetails:
        "https://www.mass.gov/how-much-will-i-get-paid",
      benefitsGuide_howBenefitsAmountsAreCalculated:
        "https://www.mass.gov/info-details/how-pfml-weekly-benefit-amounts-are-calculated",
      benefitsGuide_howBenefitsAmountsAreCalculatedAndOrChanged:
        "https://www.mass.gov/info-details/how-pfml-weekly-benefit-amounts-are-calculated-andor-changed",
      benefitsGuide_howBenefitsAmountsAreCalculatedAndOrChangedWages:
        "https://www.mass.gov/info-details/how-pfml-weekly-benefit-amounts-are-calculated-andor-changed#wages-:~:text=How%20are%20weekly%20benefit%20amounts%20calculated%3F",
      benefitsGuide_otherBenefits:
        "https://www.mass.gov/info-details/how-other-leave-and-benefits-can-affect-your-paid-family-and-medical-leave",
      benefitsGuide_pto:
        "https://www.mass.gov/info-details/how-other-leave-and-benefits-can-affect-your-paid-family-and-medical-leave#paid-time-off-through-your-employer-",
      benefitsGuide_seriousHealthCondition:
        "https://www.mass.gov/pfml-serious-health-condition",
      calculateHours: "https://www.mass.gov/pfml-calculate-hours",
      caregiverCertificationForm: "https://www.mass.gov/family-caring-form",
      caregiverRelationship:
        "https://www.mass.gov/family-caring-leave-relationships",
      caseCreationErrorGuide: "https://mass.gov/pfml/error",
      consentAgreement: "https://mass.gov/paidleave-informedconsent",
      dfml: "https://mass.gov/dfml",
      dfmlRegulations: "https://mass.gov/pfml/regulations",
      employerAccount: "https://www.mass.gov/pfml-verify-employer",
      employerReimbursements: "https://mass.gov/pfml/reimbursement",
      employerReimbursementsRequest: "https://mass.gov/PaidLeaveReimbursement",
      employersGuide: "https://mass.gov/pmfl-leave-administrators",
      extendOrUpdateLeave:
        "https://www.mass.gov/info-details/updating-or-extending-your-approved-paid-family-or-medical-leave",
      familyLeaveRequiredDocuments:
        "https://www.mass.gov/info-details/required-documents-for-your-paid-family-and-medical-leave-pfml-application#for-family-leave-applications-",
      familyMemberSeriousHealthConditionForm:
        "https://www.mass.gov/doc/certification-of-your-family-members-serious-health-condition-form/download",
      federalEmployerIdNumber: "https://mass.gov/pfml/ein",
      feedbackClaimant: "https://www.mass.gov/paidleave-claimant-feedback",
      feedbackEmployer: "https://www.mass.gov/paidleave-employer-feedback",
      findATaxProfessional:
        "https://www.mass.gov/service-details/find-a-tax-professional-to-help-with-your-taxes",
      findFeinLink:
        "https://www.mass.gov/info-details/paid-family-and-medical-leave-pfml-overview-and-benefits#find-your-employer%E2%80%99s-federal-employer-identification-number-",
      findingLegalHelp:
        "https://www.mass.gov/service-details/finding-legal-help",
      healthForms:
        "https://www.mass.gov/lists/paid-family-and-medical-leave-documents-and-forms-for-massachusetts-employees#health-forms-",
      healthcareProviderForm: "https://www.mass.gov/medical-leave-form",
      howPfmlBenefitPaymentsWork:
        "https://www.mass.gov/info-details/how-pfml-benefit-payments-work",
      howWeeklyBenefitAmountsAreCalculatedAndOrChanged:
        "https://www.mass.gov/info-details/how-pfml-weekly-benefit-amounts-are-calculated-andor-changed",
      identityProof: "https://mass.gov/pfmlsubmitinfo",
      importantTermsToKnow: "https://www.mass.gov/pfml/important-terms",
      informedConsent: "https://www.mass.gov/paidleave-informedconsent",
      intermittentLeaveGuide:
        "https://www.mass.gov/intermittent-leave-instructions",
      learnMoreAboutBondingLeave:
        "https://www.mass.gov/info-details/pfml-about-family-leave-to-bond-with-a-child",
      learnMoreAboutTaxes:
        "https://www.mass.gov/info-details/taxes-on-paid-family-and-medical-leave-pfml-benefits",
      learnMoreAboutTaxesAdditionalInfo:
        "https://www.mass.gov/info-details/taxes-on-paid-family-and-medical-leave-pfml-benefits#additional-information-",
      leaveTypeDecisionTree:
        "https://www.mass.gov/decision-tree/what-type-of-paid-leave-should-i-apply-for#s=585686&p=585686",
      mailFaxInstructions:
        "https://www.mass.gov/info-details/required-documents-for-your-paid-family-and-medical-leave-pfml-application#uploading,-mailing-or-faxing-documents-",
      manageApprovedApplication: "https://mass.gov/pfml/manage-leave",
      maxWeeklyBenefit:
        "https://www.mass.gov/info-details/paid-family-and-medical-leave-pfml-overview-and-benefits#how-much-will-i-get-paid",
      medicalBonding: "https://www.mass.gov/pfml-medical-bonding",
      multilingualServices: "https://www.mass.gov/eolwd-multilingual-services",
      multipleBirths: "https://www.mass.gov/pfml-multiple-births",
      myMassGovPrivacyNotice: "https://www.mass.gov/node/851716",
      myMassGovProfileEdit: "https://my.mass.gov/settings/profile/edit",
      myMassGovProfileEdit_nonProd:
        "https://my.test-next.tss.mass.gov/settings/profile/edit",
      myMassGovProfileView: "https://my.mass.gov/settings/profile",
      myMassGovProfileView_nonProd:
        "https://my.test-next.tss.mass.gov/settings/profile",
      mymassgov: "https://www.mass.gov/mymassgov",
      onlineAppealsForm: "https://mass.gov/PaidLeaveDecisionAppeal",
      otherLeaveAndBenefitsImpacts:
        "https://www.mass.gov/info-details/how-other-leave-and-benefits-can-affect-your-paid-family-and-medical-leave",
      overpayments:
        "https://www.mass.gov/info-details/how-pfml-benefit-payments-work#overpayments-",
      paidLeave: "https://mass.gov/PaidLeave",
      pfml: "https://www.mass.gov/pfml",
      privacyPolicy: "https://www.mass.gov/privacypolicy",
      proofOfBirthOrPlacement: "https://mass.gov/paidleavedocuments",
      ptoExamples:
        "https://www.mass.gov/info-details/how-other-leave-and-benefits-can-affect-your-paid-family-and-medical-leave#paid-time-off-through-your-employer-",
      reductionsOverview: "https://mass.gov/pfml-benefit-reductions",
      requestAnAppealForPFML: "https://mass.gov/pfml/appeal",
      requiredDocumentsAllLeaveTypes:
        "https://www.mass.gov/info-details/required-documents-for-your-paid-family-and-medical-leave-pfml-application#:~:text=Contact-,For%20all%20applications%3A%20Documents%20to%20prove%20your%20identity,-No%20matter%20what",
      requiredDocumentsBondingLeave:
        "https://www.mass.gov/info-details/required-documents-for-your-paid-family-and-medical-leave-pfml-application#:~:text=Family%20leave%20to%20bond%20with%20a%20child",
      schedulingLeaveGuide: "https://mass.gov/pfml/leave-schedules",
      seriousHealthConditionForm:
        "https://www.mass.gov/doc/certification-of-your-serious-health-condition-form/download",
      sevenDayWaitingPeriodInfo: "https://www.mass.gov/getting-paid",
      sevenDayWaitingPeriodTerm:
        "https://www.mass.gov/info-details/types-of-paid-family-and-medical-leave#important-terms-to-know-:~:text=year.%20Learn%20more.-,7%2Dday%20waiting%20period,-When%20you%20begin",
      taxGuide: "https://mass.gov/taxassistance",
      taxLiability: "https://www.mass.gov/pfml-tax-liability",
      transitionToBonding:
        "https://www.mass.gov/info-details/pfml-transitioning-from-medical-leave-to-family-leave-to-bond-with-a-child",
      updatingLeave:
        "https://www.mass.gov/info-details/updating-or-extending-your-approved-paid-family-or-medical-leave",
      usingAccruedPaidLeave: "https://mass.gov/pfml/pto",
      usingOtherLeave: "https://mass.gov/pfml-benefit-reductions",
      usingPaidTimeOff:
        "https://www.mass.gov/info-details/how-other-leave-and-benefits-can-affect-your-paid-family-and-medical-leave#paid-time-off-through-your-employer-",
      verifyEmployer: "https://www.mass.gov/pfml-verify-employer",
      whenCanIUsePFML: "https://mass.gov/pfml/qualifying-reason",
      whichPaidLeave: "https://www.mass.gov/which-paid-leave",
      zeroBalanceEmployer: "https://www.mass.gov/pfml-zero-balance-employer",
    },
    puertoRicanBirthCertificate: "https://prfaa.pr.gov/faqs",
    workVisa:
      "https://travel.state.gov/content/travel/en/us-visas/employment/temporary-worker-visas.html",
  },
  index: "/",
  user: {
    consentToDataSharing: "/user/consent-to-data-sharing",
    contactInfo: "/user/contact-info",
    convertToEmployee: "/user/convert-to-employee",
    convertToEmployer: "/user/convert-to-employer",
    settings: "/user/settings",
    taxDocuments: "/user/tax-documents",
  },
};

/**
 * @param url - path, with or without query param
 */
export const isEmployersRoute = (url: string) => {
  return Object.values(routes.employers).includes(getRouteFromUrl(url));
};

/**
 * @param url - path, with or without query param
 */
export const isApplicationsRoute = (url: string) => {
  return Object.values(routes.applications).includes(getRouteFromUrl(url));
};

/**
 * @param url - path, with or without query param
 */
const getRouteFromUrl = (url: string) => {
  let route = url;
  const queryStringIndex = route.indexOf("?");

  if (queryStringIndex !== -1) {
    route = route.substring(0, queryStringIndex);
  }

  if (route.endsWith("/")) {
    route = route.substring(0, route.length - 1);
  }

  return route;
};

export default routes;
