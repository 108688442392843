import OAuthFlowStart, {
  OAuthFlowState,
  OAuthTokenData,
  OAuthTokenResponse,
} from "src/models/OAuth";

import { AppLogic } from "../hooks/useAppLogic";
// eslint-disable-next-line lodash/import-scope
import _ from "lodash";
import { isFeatureEnabled } from "src/services/featureFlags";

export const OAuthTenantType = {
  PERSONAL: "personal",
  BUSINESS: "business",
} as const;

type OAuthTenantTypeType =
  (typeof OAuthTenantType)[keyof typeof OAuthTenantType];

// these values need to match up with those in
// api/massgov/pfml/api/authentication/__init__.py > operation_mapping
export const OAuthFlowType = {
  AUTHENTICATE: "authenticate",
  CHANGE_EMAIL: "change_email",
  CHANGE_PASSWORD: "change_pw",
  CHANGE_MFA: "change_mfa",
} as const;

export const OutcomeLoggedInType = {
  ACCOUNT_FOUND: "account_found",
} as const;

export const OutcomeNoticeType = {
  ACCOUNT_LINKED: "account_linked",
  ACCOUNT_CREATED: "account_created",
  ACCOUNT_MISMATCH: "account_mismatch",
  ACCOUNT_TAKEN: "account_taken",
  EMAIL_CHANGED: "email_changed",
  EMAIL_CONFLICT: "email_conflict",
} as const;

const profileChangeOutcomes = [
  OutcomeNoticeType.EMAIL_CHANGED,
  OutcomeNoticeType.EMAIL_CONFLICT,
];

export interface OAuthQueryParams {
  code: string;
  state: string;
  error?: string;
  error_description?: string;
}

const loggedInOutcomes = [
  OutcomeNoticeType.EMAIL_CHANGED,
  OutcomeNoticeType.EMAIL_CONFLICT,
  OutcomeNoticeType.ACCOUNT_CREATED,
  OutcomeNoticeType.ACCOUNT_LINKED,
  OutcomeLoggedInType.ACCOUNT_FOUND,
];

const errorOutcomes = [
  OutcomeNoticeType.ACCOUNT_MISMATCH,
  OutcomeNoticeType.ACCOUNT_TAKEN,
];

class OpenIdConnect {
  PORTAL_USER_TYPE_KEY = "PORTAL_USER_TYPE";
  OAUTH_FLOW_START_KEY = "OAUTH_FLOW_START";
  OAUTH_STATE_KEY = "OAUTH_STATE";
  OAUTH_END_SESSION_URL_KEY = "OAUTH_END_SESSION_URL";
  OAUTH_NEXT_LOCATION_KEY = "OAUTH_NEXT_LOCATION";

  saveOAuthFlowStart(value: string) {
    localStorage.setItem(this.OAUTH_FLOW_START_KEY, value);
  }

  getOAuthFlowStart() {
    return localStorage.getItem(this.OAUTH_FLOW_START_KEY);
  }

  getFlowStartObject(): OAuthFlowStart | null {
    const storedValue = this.getOAuthFlowStart();
    if (!storedValue) {
      return null;
    }
    return new OAuthFlowStart(JSON.parse(storedValue));
  }

  clearOAuthFlowStart() {
    localStorage.removeItem(this.OAUTH_FLOW_START_KEY);
  }

  saveEndSessionUrl(value: string) {
    localStorage.setItem(this.OAUTH_END_SESSION_URL_KEY, value);
  }

  getEndSessionUrl(): string | null {
    return localStorage.getItem(this.OAUTH_END_SESSION_URL_KEY);
  }

  clearEndSessionUrl() {
    localStorage.removeItem(this.OAUTH_END_SESSION_URL_KEY);
  }

  saveOAuthState(value: string) {
    localStorage.setItem(this.OAUTH_STATE_KEY, value);
  }

  getOAuthState() {
    return localStorage.getItem(this.OAUTH_STATE_KEY);
  }

  encodeFlowStartState(state: OAuthFlowState): string {
    return Buffer.from(JSON.stringify(state)).toString("base64");
  }

  decodeFlowStartState(encodedState: string): OAuthFlowState {
    return JSON.parse(Buffer.from(encodedState, "base64").toString("utf-8"));
  }

  clearOAuthState() {
    localStorage.removeItem(this.OAUTH_STATE_KEY);
  }

  setUserType(usertype: string) {
    localStorage.setItem(this.PORTAL_USER_TYPE_KEY, usertype);
  }

  getUserType(): string {
    return localStorage.getItem(this.PORTAL_USER_TYPE_KEY) || "";
  }

  saveNextLocation(nextLocation: string) {
    localStorage.setItem(this.OAUTH_NEXT_LOCATION_KEY, nextLocation);
  }

  getNextLocation(): string | null {
    return localStorage.getItem(this.OAUTH_NEXT_LOCATION_KEY);
  }

  clearNextLocation() {
    localStorage.removeItem(this.OAUTH_NEXT_LOCATION_KEY);
  }

  hasUserType(): boolean {
    return this.getUserType() !== "";
  }

  resetUserType() {
    localStorage.removeItem(this.PORTAL_USER_TYPE_KEY);
  }

  isLmgEnabled(): boolean {
    return isFeatureEnabled("enableLmgAuth");
  }

  isLmgEnforced(): boolean {
    return isFeatureEnabled("enforceLmgAuth");
  }

  showModeTestButtons(): boolean {
    return isFeatureEnabled("enableLmgAuthModeTestButtons");
  }

  isOIDCAuthenticated(): boolean {
    const stateData = this.getCachedOAuthStateData();
    if (!stateData) {
      return false;
    }
    if (this.isOAuthStateDataValid(stateData)) {
      return !this.isErrorOutcome(stateData.outcomes);
    } else {
      this.clearOAuthState();
      return false;
    }
  }

  getCachedOAuthStateData(): OAuthTokenResponse | null {
    if (!this.isLmgEnabled()) {
      return null;
    }

    const storedValue = this.getOAuthState();
    if (!storedValue) {
      return null;
    }

    const stateData = new OAuthTokenResponse(JSON.parse(storedValue));
    return stateData;
  }

  getCachedOAuthTokenData(): OAuthTokenData | null {
    const stateData = this.getCachedOAuthStateData();
    if (!stateData) {
      return null;
    }
    if (this.isOAuthStateDataValid(stateData)) {
      return stateData.token_data;
    } else {
      this.clearOAuthState();
      return null;
    }
  }

  isOAuthStateDataValid(stateData: OAuthTokenResponse): boolean {
    if (stateData.token_data === undefined) {
      throw new Error("Token data is missing from OAuth state");
    }

    const tokenData = stateData.token_data;
    const expiryDate = new Date(
      (parseInt(tokenData.not_before) +
        parseInt(tokenData.id_token_expires_in)) *
        1000
    );
    return expiryDate > new Date();
  }

  signOut() {
    this.clearOAuthState();
    this.clearEndSessionUrl();
  }

  resetAllData() {
    this.clearOAuthState();
    this.resetUserType();
    this.clearOAuthFlowStart();
    this.clearEndSessionUrl();
  }

  outcomesNeedNotice(outcomes: string[]) {
    return (
      _.intersection(outcomes, Object.values(OutcomeNoticeType)).length > 0
    );
  }

  outcomesHaveProfileChange(outcomes: string[]) {
    return _.intersection(outcomes, profileChangeOutcomes).length > 0;
  }

  isLoggedInOutcome(outcomes: string[]) {
    return _.intersection(outcomes, loggedInOutcomes).length > 0;
  }

  isErrorOutcome(outcomes: string[]) {
    return _.intersection(outcomes, errorOutcomes).length > 0;
  }

  getFlowOutcomes() {
    const storedValue = this.getOAuthState();
    if (!storedValue) {
      return ["unknown"];
    }
    return JSON.parse(storedValue).outcomes;
  }

  startOauthFlow = async (
    appLogic: AppLogic,
    authServer: string,
    flow: string,
    asUrlReplace = false,
    forceLogin = false
  ) => {
    const data = await appLogic.auth.getOAuthFlowStart(authServer, flow);
    if (!data) return;
    this.saveOAuthFlowStart(JSON.stringify(data.lmgAuth));
    if (!!data.lmgAuth.end_session_url) {
      const mmgLogoutUrlObj = new URL(data.lmgAuth.end_session_url);
      const returnUrl = mmgLogoutUrlObj.searchParams.get(
        "post_logout_redirect_uri"
      );
      if (!!returnUrl) {
        mmgLogoutUrlObj.searchParams.set(
          "post_logout_redirect_uri",
          this.ensureUrlSameOrigin(returnUrl, window.location.origin)
        );
      }
      this.saveEndSessionUrl(mmgLogoutUrlObj.toString());
    }
    const url = this.adjustAuthUrlForDelegatedReturn(
      this.adjustAuthUrlForForcedLogin(flow, forceLogin, data.lmgAuth.auth_uri)
    );

    if (asUrlReplace) {
      location.replace(url);
    } else {
      appLogic.portalFlow.goTo(url, {}, { redirect: true });
    }
  };

  adjustAuthUrlForForcedLogin = (
    flow: string,
    forceLogin: boolean,
    authUrl: string
  ): string => {
    if (flow === OAuthFlowType.AUTHENTICATE && forceLogin) {
      return authUrl + "&prompt=login";
    }
    return authUrl;
  };

  adjustAuthUrlForDelegatedReturn = (authUrl: string): string => {
    if (!isFeatureEnabled("enableDelegatedOAuthDetection")) {
      return authUrl;
    }
    // When URL origins are different, the handoff to MMG will include the delegated return
    // hostname in the oauth state data. An assigned hostname in state data will trigger a
    // redirect to the delegated host after returning from MMG.
    const authUrlObj = new URL(authUrl);
    const returnUrl = authUrlObj.searchParams.get("redirect_uri")!;
    if (returnUrl === null || returnUrl === "") {
      return authUrl;
    }

    const changedUrl = this.ensureUrlSameOrigin(
      returnUrl,
      window.location.origin
    );
    if (
      changedUrl !== returnUrl ||
      isFeatureEnabled("forceDelegatedOAuthReturn")
    ) {
      const currStateVal = authUrlObj.searchParams.get("state")!;
      const stateObj = this.decodeFlowStartState(currStateVal);
      const changedUrlObj = new URL(changedUrl);
      const newStateObj = new OAuthFlowState({
        ...stateObj,
        ret_origin: changedUrlObj.origin,
      });
      const newStateVal = this.encodeFlowStartState(newStateObj);
      authUrlObj.searchParams.set("state", newStateVal);
    }

    return authUrlObj.toString();
  };

  ensureUrlSameOrigin = (url: string, origin: string): string => {
    const urlObj = new URL(url);
    const originObj = new URL(origin);
    if (urlObj.origin === originObj.origin) {
      return url;
    }
    const newUrl = new URL(url);
    newUrl.host = originObj.host;
    newUrl.protocol = originObj.protocol;
    return newUrl.toString();
  };

  processOauthReturn = async (
    appLogic: AppLogic,
    flowStart: OAuthFlowStart,
    oAuthQueryParams: OAuthQueryParams
  ): Promise<OAuthTokenResponse | undefined> => {
    const oAuthState = await appLogic.auth.getOAuthToken(
      flowStart,
      oAuthQueryParams.code,
      oAuthQueryParams.state
    );
    this.saveOAuthState(JSON.stringify(oAuthState));
    this.clearOAuthFlowStart();
    return oAuthState;
  };

  getTokenPayload = () => {
    const tokenData = this.getCachedOAuthTokenData();
    if (!tokenData) {
      throw new Error("No cached OAuth token data found");
    }
    const idToken = tokenData.id_token;
    if (!idToken) {
      throw new Error("No id token found in OAuth token data");
    }
    const idTokenParts = idToken.split(".");
    const idTokenPayload = JSON.parse(
      Buffer.from(idTokenParts[1], "base64").toString("utf-8")
    );
    return idTokenPayload;
  };

  getClaimEmailAddress = () => {
    return this.getTokenPayload().email;
  };

  isOAuthTenantType = (input: string): input is OAuthTenantTypeType => {
    return Object.values(OAuthTenantType).includes(
      input as OAuthTenantTypeType
    );
  };
}

export default new OpenIdConnect();
